@layer components {
  .link {
    @apply inline-flex w-fit items-center justify-center gap-1 rounded bg-transparent px-[2px] py-[6px] text-base text-primary-100;

    .svg-inline--fa {
      color: inherit;
    }

    &:hover {
      @apply text-primary-80;
      @apply underline;
    }

    &:active {
      @apply text-primary-80;
    }

    &:focus-visible {
      @apply focus;
    }

    &:disabled {
      @apply text-neutral-100 opacity-30;

      &:hover {
        @apply no-underline;
      }
    }

    &.link--neutral {
      @apply text-neutral-64;

      &:active {
        @apply text-neutral-80;
      }
    }

    &--large {
      @apply link py-[10px];
    }

    &--sm {
      @apply link py-1 text-sm;
    }
  }
}
