@layer components {
  .alert--blue,
  .alert-info,
  .alert {
    @apply border--main relative elevation rounded-lg bg-status-info-light pl-4 py-3 pr-3 text-neutral-80;

    .svg-inline--fa {
      color: inherit;
    }
  }

  .alert--gray,
  .alert-neutral {
    @apply alert bg-status-neutral-light;
  }

  .alert--green,
  .alert-success {
    @apply alert bg-status-success-light;
  }

  .alert--yellow,
  .alert-caution {
    @apply alert bg-status-caution-light;
  }

  .alert--red,
  .alert-critical {
    @apply alert bg-status-critical-light;
  }

  .alert--purple,
  .alert-pending {
    @apply alert bg-status-excite-light;
  }

  .alert-inline {
    @apply font-bold uppercase whitespace-nowrap mx-1 bg-transparent shadow-none;

    &.alert--gray,
    &.alert-neutral {
      @apply text-status-neutral;
    }

    &.alert--green,
    &.alert-success {
      @apply text-status-success;
    }

    &.alert--yellow,
    &.alert-caution {
      @apply text-status-caution;
    }

    &.alert--red,
    &.alert-critical {
      @apply text-status-critical;
    }

    &.alert--purple,
    &.alert-pending {
      @apply text-status-excite;
    }
  }
}
