@layer components {
  .dropdown {
    @apply inline-block relative;

    &-link {
      @apply flex items-center cursor-pointer hover:no-underline text-neutral-80;
    }

    &-menu {
      @apply hidden w-fit absolute top-full left-0 elevation--overlay z-[5] p-2 rounded-lg bg-white mt-2;

      &.display-above {
        transform: translateY(calc(-100% - 1.5rem));
      }

      &-right {
        @apply right-0 left-auto;
      }

      &.open {
        display: block !important;
      }
    }

    &-menu-link {
      @apply block transition-all ease-in-out duration-200 cursor-pointer rounded-md-sm bg-transparent p-2 min-w-10 text-neutral-80 text-left no-underline w-full;

      &.dropdown-menu-link-border-top {
        @apply border-solid border-neutral-8 border-0 border-t;
      }

      &:hover:not(.disabled):not(.btn-disabled):not(:disabled) {
        @apply bg-neutral-3 no-underline;

        a {
          @apply no-underline;
        }
      }

      &:disabled {
        @apply opacity-50;
      }

      &.active:not(.disabled):not(.btn-disabled):not(:disabled) {
        @apply cursor-pointer;
      }

      a,
      .svg-inline--fa {
        @apply text-inherit;
      }
    }
  }

  .dropdown--react {
    @apply dropdown;

    &-menu {
      @apply dropdown-menu;
    }

    &-menu-link {
      @apply dropdown-menu-link;
    }
  }
}
