@tailwind base;
// @import "styles/tailwind-scoped-preflight";

// @todo It turns on that the scoped preflight stuff does not work well. It
// has higher specificity than regular tailwind classes, so often takes
// precedence.
.position-page {
  * {
    border-width: 0;
    border-style: solid;
    border-color: theme("borderColor.DEFAULT", currentColor);
  }

  dl,
  dd {
    margin: 0;
  }
}

@tailwind components;
@tailwind utilities;

@import "styles/components/alerts";
@import "styles/components/buttons";
@import "styles/components/card";
@import "styles/components/dropdown";
@import "styles/components/links";
@import "styles/components/module-card";
@import "styles/components/navigation";
@import "styles/components/pagination";
@import "styles/components/tooltip";
@import "styles/decorations";
@import "styles/utilities/badges";
@import "styles/utilities/helpers";
@import "styles/utilities/icons";
@import "styles/utilities/responsive";

@layer base {
  html {
    @apply bg-primary-3;
    scrollbar-color: theme("colors.neutral.13") transparent;
    scrollbar-width: thin;
  }

  body {
    @apply text-base;

    .svg-inline--fa {
      // The default for FontAwesome is 1em, which in the case of our app, would be 13px.
      // We generally want icons to be 14px tall, so this overrides that behavior.
      height: 1.077em;
    }
  }

  :focus-visible {
    outline: none;
  }

  h1,
  h2,
  h3,
  h4,
  p,
  span {
    margin: 0;
  }

  h1 {
    @apply text-xl-bold;
  }

  h2 {
    @apply text-large;
  }

  h3 {
    @apply text-base-bold;
  }

  p {
    @apply text-base;
  }

  button {
    border: 0;
  }
}
