@layer utilities {
  // Note that the bottom padding is set at 13.5rem to act as a kind of page
  // footer. This is likely to evolve as we build out an actual footer
  // partial/component to use.
  .page-pad {
    @apply px-3 pt-3 pb-[13.5rem] sm:px-6 sm:pt-6 1120:px-8 1120:pt-8;
  }

  // If the page-pad class is not being used at the top level in the DOM, it's
  // occasionally useful to have a version that doesn't include the large bottom
  // padding intended for the footer.
  .page-pad-sans-large-bottom {
    @apply p-3 sm:p-6 1120:p-8;
  }

  .page-pad-x {
    @apply px-3 sm:px-6 1120:px-8;
  }

  .page-pad-y {
    @apply pt-3 pb-[13.5rem] sm:pt-6 1120:pt-8;
  }

  .page-pad-b {
    @apply pb-[13.5rem];
  }

  .page-pad-t {
    @apply pt-3 sm:pt-6 1120:pt-8;
  }

  .page-gap {
    @apply gap-3 sm:gap-6 1120:gap-8;
  }

  .page-margin {
    @apply m-3 sm:m-6 1120:m-8;
  }

  .page-margin-x {
    @apply mx-3 sm:mx-6 1120:mx-8;
  }

  .page-margin-y {
    @apply my-3 sm:my-6 1120:my-8;
  }

  .page-margin-b {
    @apply mb-3 sm:mb-6 1120:mb-8;
  }

  .page-margin-t {
    @apply mt-3 sm:mt-6 1120:mt-8;
  }
}
