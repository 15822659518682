@layer utilities {
  // Focus
  .focus {
    z-index: 1;
    box-shadow:
      0px 0px 0px 1px theme("colors.white"),
      0px 16px 12px 0px theme("colors.white/.08") inset,
      0px 0px 0px 2px theme("colors.primary.50");
  }

  //  Borders
  .border--main {
    box-shadow:
      0px 1px 3px 0px theme("colors.neutral.3"),
      0px 0px 0px 1px theme("colors.neutral.8") inset,
      0px -1px 0px 0px theme("colors.neutral.3") inset;
  }

  .border--main-hover {
    box-shadow:
      0px 1px 3px 0px theme("colors.neutral.3"),
      0px 0px 0px 1px theme("colors.neutral.100/.24") inset,
      0px -1px 0px 0px theme("colors.neutral.3") inset;
  }

  .border--focus {
    box-shadow:
      0px -1px 0px 0px theme("colors.neutral.3") inset,
      0px 0px 0px 1px theme("colors.white"),
      0px 0px 0px 2px theme("colors.primary.50"),
      0px 0px 0px 1px theme("colors.neutral.8") inset;
  }

  // Error
  .border--error {
    box-shadow:
      0px 0px 0px 1px theme("colors.neutral.8") inset,
      0px 0px 0px 1px theme("colors.white"),
      0px 0px 0px 2px theme("colors.status.critical"),
      0px -1px 0px 0px theme("colors.neutral.3") inset;
  }

  .border--elevation {
    box-shadow:
      0px 1px 2px 0px theme("colors.neutral.8"),
      0px -1px 0px 0px theme("colors.neutral.3") inset,
      0px 0px 0px 1px theme("colors.neutral.8") inset,
      0px 0px 0px 1px theme("colors.neutral.8-solid") inset;
  }

  // Box Shadow Borders + Elevation
  .elevation {
    box-shadow:
      0px 3px 4px -1px theme("colors.neutral.3"),
      0px 0px 0px 1px theme("colors.neutral.8") inset,
      0px -1px 0px 0px theme("colors.neutral.3") inset;
  }

  .elevation--secondary {
    box-shadow:
      0px 1px 1px 0px theme("colors.neutral.100/.04"),
      0px -1px 0px 0px theme("colors.neutral.3") inset,
      0px 0px 0px 1px theme("colors.neutral.100/.16") inset;
  }

  .elevation--overlay {
    box-shadow:
      0px 4px 8px -1px theme("colors.neutral.8"),
      0px -1px 0px 0px theme("colors.neutral.3") inset,
      0px 0px 0px 1px theme("colors.neutral.8");
  }

  .elevation--bottom {
    box-shadow:
      0px 1px 2px 0px theme("colors.neutral.100/.24"),
      0px 16px 12px 0px theme("colors.white/.08") inset,
      0px -1px 0px 0px theme("colors.neutral.100/.12") inset;
  }

  .elevation--profound-left {
    box-shadow:
      -4px 2px 8px -1px rgba(12, 20, 75, 0.08),
      0px -1px 0px 0px rgba(12, 20, 75, 0.03) inset;
  }

  // Gradients
  .bg-gradient-neutral {
    @apply bg-white bg-gradient-to-b from-neutral-100/0 to-neutral-100/2;
  }

  .bg-gradient-neutral-10 {
    @apply bg-gradient-to-t from-neutral-100/10 to-neutral-100/10;
  }

  .bg-gradient-white {
    @apply bg-gradient-to-t from-white/8 to-white/8;
  }

  .bg-gradient-blue-to-b {
    background: linear-gradient(180deg, rgb(248, 250, 255) 0%, rgb(237, 242, 255) 100%),
      linear-gradient(0deg, rgb(237, 242, 255), rgb(237, 242, 255));
  }

  .bg-gradient-blue-to-l {
    background: linear-gradient(
      272.01deg,
      theme("colors.primary.100/0") -0.09%,
      theme("colors.primary.8") 100%
    );
  }

  .bg-radial-blue {
    background: radial-gradient(
      60.5% 60.5% at 50% 39.5%,
      theme("colors.primary.8") 0%,
      theme("colors.primary.100/0") 100%
    );
  }

  // Used in the HCP Position Forecast Widget
  .bg-gradient-primary-8-to-b {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0) 100%),
      linear-gradient(0deg, rgba(236, 242, 255, 1), rgba(236, 242, 255, 1));
  }

  .bg-gradient-primary-50-to-b {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0) 100%),
      linear-gradient(0deg, rgba(141, 176, 255, 1), rgba(141, 176, 255, 1));
  }

  .bg-gradient-primary-100-to-b {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0) 100%),
      linear-gradient(0deg, rgba(027, 098, 255, 1), rgba(027, 098, 255, 1));
  }

  .bg-gradient-sunburst-50-to-b {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0) 100%),
      linear-gradient(0deg, rgba(255, 208, 041, 0.5), rgba(255, 208, 041, 0.5));
  }
}
