@layer components {
  .page-nav {
    @apply md:h-14 h-auto max-h-[114px] p-0 items-center border-b border-l-0 border-r-0 border-t-0 border-solid border-neutral-8 bg-white grid w-full grid-cols-12;
    // Content Blocks

    h1 {
      @apply text-large text-neutral-100;
      text-wrap: nowrap;
    }

    .btn-group {
      .btn {
        line-height: initial;
      }
    }

    .title-block,
    .title-block-sm,
    .title-block-lg {
      @apply min-h-[3.5rem] col-span-6 pr-2 gap-x-2 md:pl-3;
    }

    .title-block {
      @apply md:col-span-3 lg:col-span-2 col-start-1;
    }

    .title-block-sm {
      @apply md:col-span-2 lg:col-span-1;
    }

    .title-block-lg {
      @apply md:col-span-4 lg:col-span-3;
    }

    .link-block,
    .link-block-xs,
    .link-block-sm,
    .link-block-lg {
      @apply w-full justify-self-center h-[3.5rem] col-span-12 row-start-2 md:row-start-1 border-b-0 border-l-0 border-r-0 border-t border-solid border-neutral-8 px-2;
    }

    .link-block {
      @apply md:col-span-6 md:col-start-4 xl:col-span-8 xl:col-start-3;
    }

    .link-block-xs {
      @apply md:col-span-4 md:col-start-5 1225:col-span-2 1225:col-start-6;
    }

    .link-block-sm {
      @apply md:col-span-4 md:col-start-5 xl:col-span-6 xl:col-start-4;
    }

    .link-block-lg {
      @apply md:col-span-8 md:col-start-3 xl:col-span-10 xl:col-start-2;
    }

    .action-block,
    .action-block-lg {
      @apply col-span-6 col-start-7 pr-3 gap-x-2;
    }

    .action-block {
      @apply md:col-span-3 md:col-start-10 lg:col-span-2 lg:col-start-11 min-h-[3.5rem];
    }

    .action-block-lg {
      @apply md:col-span-4 md:col-start-9 1225:col-span-5 1225:col-start-8;
    }
  }

  .main-nav-closed:not(:has(.alert-top)) {
    .page-nav {
      @apply md:pl-5;
    }
  }

  .main-nav-open:not(:has(.alert-top)) {
    .page-nav {
      @apply md:pl-0;
    }
  }

  .utility-nav {
    @apply bg-primary-3-solid/70 relative py-2 px-3 border-l-0 border-r-0 border-t-0 border-b border-solid border-neutral-8 w-full flex gap-2;
  }

  .nav-hamburger {
    @apply h-full w-12 min-w-[3rem] grid md:hidden place-content-center border-b-0 border-l-0 border-r border-t-0 border-solid border-neutral-8;
  }
}
