@layer components {
  .module-card {
    @apply elevation border border-neutral-8-solid bg-white rounded-xl page-margin-b;

    &__header {
      @apply p-4 flex items-center justify-between;

      .module-title {
        @apply text-base-bold;
      }

      .module-action {
        @apply btn--sm btn--secondary;
      }
    }

    .module-subsection-title {
      @apply pt-3 px-4 bg-white text-base-bold;
    }

    &__body {
      @apply p-4 flex flex-col gap-4 min-h-[3rem] border-solid border-t border-r-0 border-l-0 border-b-0 border-neutral-8;

      label {
        @apply text-base;
      }

      .label-bold {
        label {
          @apply text-base-bold;
        }
      }
    }

    // If banner is placed between header and body, this removes the border from the module body
    .banner + .module-card__body {
      @apply border-t-0;
    }

    .module-card-list-item {
      @apply flex items-center justify-between gap-2;

      .dropdown-link {
        @apply min-w-[1rem];
      }

      // For left-aligned modules
      &.left-align {
        display: grid;
        grid-template-columns: 2fr 3fr;
      }
    }
  }

  .accordion-module-panel {
    @apply relative rounded-lg border-neutral-8 border-solid border;

    &__overlay {
      @apply absolute top-0 left-0 z-10 bg-white w-full;
    }

    &__header {
      @apply text-base-bold gap-3 box-border flex items-center justify-between p-4 w-full bg-primary-3 border-neutral-8 border-0 border-solid border-b-0;

      &-icon {
        @apply shrink-0 cursor-pointer grid place-content-center rounded-full w-6 h-6 border--main bg-white transition-all duration-500 ease-[cubic-bezier(0.04,0.62,0.23,0.98)];

        span {
          @apply grid place-content-center box-border p-1;
        }
      }
    }

    &__body {
      @apply transition-all duration-500 ease-[cubic-bezier(0.04,0.62,0.23,0.98)] h-auto opacity-100;

      &-items {
        @apply duration-200 p-4 origin-top-center;
      }
    }

    &.body-collapsed {
      .accordion-module-panel__header {
        @apply border-none;
      }

      .accordion-module-panel__header-icon {
        @apply rotate-180;
      }

      .accordion-module-panel__body {
        @apply h-0 opacity-0 overflow-hidden;
      }
    }

    &__content {
      @apply overflow-hidden border-neutral-8 border-solid border-b-0 border-x-0 border;

      &-items {
        @apply origin-top-center p-4;
      }
    }
  }

  // Selector Modules

  .selector-module {
    @apply relative mb-4 border border-solid border-neutral-8 transition-all duration-200 ease-in-out rounded-xl w-full;

    .check {
      @apply flex absolute top-0 right-0 translate-x-[35%] translate-y-[-35%] w-3.5 h-3.5 justify-center items-center opacity-0 transition-all duration-200 ease-in-out border border-solid border-primary-100 bg-primary-100 text-white text-sm rounded-full;
      box-shadow:
        0px 0px 0px 1px theme("colors.neutral.100/.08") inset,
        0px -1px 0px 0px theme("colors.neutral.100/.03") inset;

      .fa-check {
        @apply text-white w-[7px] h-[7px] font-[7px];
      }
    }

    .selector-text {
      @apply text-neutral-64 text-base;
    }

    .module-header,
    .module-body {
      @apply transition-all duration-200 border-none rounded-lg ease-in-out py-4 px-3 font-normal;
    }

    .module-title {
      @apply normal-case;
    }
  }

  .selector-module:hover,
  .selector-module.selected,
  .selector-module--highlight:checked + label .selector-module,
  [type="radio"]:checked + .selector-module {
    @apply cursor-pointer border border-solid border-primary-100 rounded-xl bg-primary-3;

    .check {
      @apply opacity-100 w-3.5 h-3.5 font-[7px];

      .svg-inline--fa {
        @apply w-[7px] h-[7px];
      }
    }

    .module-header--highlight {
      @apply bg-primary-100 text-white rounded-tl-lg rounded-tr-lg transition-all duration-200 ease-in-out;
    }

    .selector-text {
      @apply text-primary-100 text-base-bold;
    }
  }

  .selector-module-inline {
    @apply bg-neutral-3;

    .module-body {
      @apply flex items-center bg-transparent p-1;

      img {
        @apply pr-2;
      }
    }

    .check {
      @apply w-3.5 h-3.5;

      .svg-inline--fa {
        @apply w-[7px] h-[7px];
      }
    }
  }

  .selector-module-button {
    @extend .selector-module;
    @extend .selector-module-inline;

    @apply m-0 h-10 text-center bg-white rounded-md hover:rounded-md;

    &:has(:focus-visible),
    &.selected {
      @apply rounded-md bg-primary-3 border-primary-100;
    }

    &-body {
      @apply h-10 rounded-md;
    }
  }
}
