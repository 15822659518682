@layer components {
  .btn {
    @apply flex w-fit min-w-[32px] cursor-pointer h-8 items-center justify-center gap-1.5 rounded-md px-3 py-[6px] text-base-bold no-underline bg-transparent disabled-styles;
    text-wrap: nowrap;

    .svg-inline--fa {
      color: inherit;
    }

    &:focus-visible {
      @apply border--focus;
    }

    &--large {
      @apply btn min-w-[40px] rounded-lg h-10 px-4 py-[10px];
    }

    &--sm {
      @apply btn gap-1 min-w-fit rounded-md-sm h-6 px-2 py-1 text-sm;
    }

    &--icon {
      @apply btn w-6 min-w-[1.5rem] h-6 rounded-md-sm grid place-content-center;
    }
  }

  .btn.btn--primary,
  a.btn--primary,
  .btn--primary {
    @apply elevation--bottom bg-primary-100 text-white;

    &:hover:not(.disabled):not(.btn-disabled):not(:disabled) {
      @apply bg-gradient-white no-underline;
    }

    &:active:not(.disabled):not(.btn-disabled):not(:disabled) {
      @apply bg-gradient-neutral-10;
    }
  }

  .btn.btn--secondary,
  a.btn--secondary,
  .btn--secondary {
    @apply bg-gradient-neutral elevation--secondary bg-white text-neutral-100;

    &:hover:not(.disabled):not(.btn-disabled):not(:disabled) {
      @apply bg-gradient-white border--elevation no-underline;
    }

    &:active:not(.disabled):not(.btn-disabled):not(:disabled) {
      @apply bg-neutral-3-solid;
    }
  }

  a.btn--caution-light,
  .btn--caution-light {
    @apply elevation  bg-status-caution-light text-status-caution;

    &:hover:not(.disabled):not(.btn-disabled):not(:disabled) {
      @apply bg-gradient-white no-underline;
    }

    &:active:not(.disabled):not(.btn-disabled):not(:disabled) {
      @apply bg-gradient-neutral-10;
    }
  }

  a.btn--critical,
  .btn--critical {
    @apply elevation--bottom bg-status-critical text-white;

    &:hover:not(.disabled):not(.btn-disabled):not(:disabled) {
      @apply bg-gradient-white text-white no-underline;
    }

    &:active:not(.disabled):not(.btn-disabled):not(:disabled) {
      @apply bg-gradient-neutral-10;
    }
  }

  .btn--excite-light {
    @apply elevation  bg-status-excite-light text-status-excite;

    &:hover:not(.disabled):not(.btn-disabled):not(:disabled) {
      @apply bg-gradient-white border--elevation no-underline;
    }

    &:active:not(.disabled):not(.btn-disabled):not(:disabled) {
      @apply bg-gradient-white;
    }
  }

  a.btn--ghost,
  .btn--ghost {
    @apply bg-transparent text-neutral-100 rounded-md-sm;

    &:hover:not(.disabled):not(.btn-disabled):not(:disabled) {
      @apply bg-neutral-8 no-underline;
    }

    &:active:not(.disabled):not(.btn-disabled):not(:disabled) {
      @apply bg-neutral-3;
    }
  }

  .btn--success-light {
    @apply elevation  bg-status-success-light text-status-success;

    &:hover:not(.disabled):not(.btn-disabled):not(:disabled) {
      @apply bg-gradient-white border--elevation no-underline;
    }

    &:active:not(.disabled):not(.btn-disabled):not(:disabled) {
      @apply bg-gradient-white;
    }
  }

  .btn-group {
    @apply max-h-[56px] text-center;

    .btn {
      @apply border--elevation rounded-none flex-shrink-0 bg-gradient-neutral whitespace-nowrap text-base inline-block h-[40px] py-[11px] mr-[-1px] disabled-styles;
      color: theme("colors.neutral.64");

      &:first-of-type {
        @apply rounded-l-lg;
      }
      &:last-of-type {
        @apply rounded-r-lg;
      }

      &:hover:not(.disabled):not(.btn-disabled):not(:disabled) {
        color: theme("colors.neutral.100");
        text-decoration-line: none;
      }

      &.active:not(.disabled):not(.btn-disabled):not(:disabled) {
        @apply border--main;
        position: relative;
        background-color: theme("colors.neutral.8-solid");
        color: theme("colors.neutral.100");
        text-decoration-line: none;
      }
    }
  }

  .disabled-styles {
    &.btn-disabled,
    &.btn-disabled:hover,
    &.disabled,
    &.disabled:hover,
    &:disabled,
    &:disabled:hover {
      @apply opacity-50 cursor-default select-none;
    }
  }
}
