@layer utilities {
  .translate-z-0 {
    transform: translateZ(0);
  }

  .border-t-neutral-8 {
    border-top: 1px solid theme("colors.neutral.8");
  }
  .border-r-neutral-8 {
    border-right: 1px solid theme("colors.neutral.8");
  }
  .border-b-neutral-8 {
    border-bottom: 1px solid theme("colors.neutral.8");
  }
  .border-l-neutral-8 {
    border-left: 1px solid theme("colors.neutral.8");
  }

  // Margins
  // Removes bottom margin from an element that is the last child
  .mb-0-lc > *:last-child {
    margin-bottom: 0;
  }

  .text-sideways {
    transform: rotate(-180deg);
    writing-mode: tb-rl;
  }

  .text-sm-all {
    & * {
      @apply text-sm;
    }
  }

  .text-wrap {
    text-wrap: wrap;
  }

  .text-nowrap {
    text-wrap: nowrap;
  }

  .text-wrap-auto {
    text-wrap: auto;
  }
}
