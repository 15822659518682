@layer components {
  .paginate {
    @apply mx-auto w-fit;
    .pagination {
      @apply m-0 gap-2 flex;
    }

    .pagination > .current {
      @apply btn--sm btn--primary;
    }

    .pagination > .previous_page,
    .pagination > a,
    .pagination > button,
    .pagination > .next_page {
      @apply btn--sm btn--secondary text-neutral-80;
    }

    @media (max-width: 640px) {
      .pagination a {
        display: none;
      }

      .pagination a.active,
      .pagination a:first-child,
      .pagination a:last-child,
      .pagination a:nth-child(2),
      .pagination a:nth-child(3),
      .pagination a:nth-last-child(3),
      .pagination a:nth-last-child(2) {
        display: inline;
      }
    }
  }
}
