@layer utilities {
  .icon-10 {
    font-size: 0.625rem;
  }

  .icon-14 {
    font-size: 0.875rem;
  }

  .icon-16 {
    font-size: 1rem;
  }

  .icon--round {
    @apply flex h-12 w-12 shrink-0 grow-0 items-center justify-center rounded-full bg-primary-8-solid text-primary-100;
  }

  // classes that can be applied to Fontawesome Duotone icons
  .icon--yellow,
  .icon--caution,
  .icon--red,
  .icon--critical,
  .icon--green,
  .icon--success {
    --fa-secondary-opacity: 1;
    stroke: theme("colors.neutral.8");
    stroke-width: 16;
  }

  .icon--yellow,
  .icon--caution {
    --fa-primary-color: theme("colors.status.caution");
    --fa-secondary-color: theme("colors.status.caution-light");
  }

  .icon--red,
  .icon--critical {
    --fa-primary-color: theme("colors.status.critical");
    --fa-secondary-color: theme("colors.status.critical-light");
  }

  .icon--green,
  .icon--success {
    --fa-primary-color: theme("colors.status.success");
    --fa-secondary-color: theme("colors.status.success-light");
  }
}
